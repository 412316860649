.portfolioItem-wrapper {
  width: 100%;
  border-radius: 2px;
  border: 2px solid black;
  transition: transform 100ms, box-shadow 100ms;
  background-color: white;
}
.portfolioItem-header {
  font-size: 1.6rem;
  text-transform: uppercase;
  padding: 0.5rem;
}
.portfolioItem-wrapper p {
  padding: 0.5rem;
  margin: 0;
}
.portfolioItem-wrapper ul {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem;
  overflow: hidden;
}
.portfolioItem-wrapper ul li {
  float: left;
  padding: 0 0.5rem 0 0.5rem;
}