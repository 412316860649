@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

html {
	scroll-behavior: smooth;
}
body {
	font-family: 'Inconsolata', monospace;
}
@keyframes caret {
	50% {
		border-color: transparent;
	}
}
.title-wrapper {
	background-image: url(../../public/assets/images/desk-2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	height: 110vh;
	animation: gradient 15s ease infinite;
} 
.main-content {
	position: relative;
	color: rgb(255, 255, 255);
	height: 20rem;
	width: 40rem;
	left: 50%;
	top: 35%;
	transform: translate(-60%, -50%);
	text-align: center;
	border-radius: 3px;
	align-items: center;
	justify-content: center;
}
.name-title {
	font-size: 4em;
	display: flex;
	justify-content: center;
}
.name-title .last {
	color:rgb(255, 0, 212);
	padding-left: 10px;
	padding-right: 10px;
}
.name-title .icon:hover {
	transform: translateY(-5px);
}
.main-content h1 {
	font-size: 1.5em;
}
.description {
	font-size: 1em;
}
.wrapper {
	left: 50%;
}
.wrapper i {
  padding: 10px;
  transition: all ease-in-out 100ms;
}
.button-wrapper {
	margin-top: 50px;
}
.button-wrapper a {
	text-decoration: none;
	position: relative;
}
.button {
	padding: 10px 10px 10px 10px;
	transition: all ease-in-out 100ms;
	color: white;
	border-radius: 5px;
	background-color: #0063c72f;
	width: 5rem;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
}
.button:hover {
	background-color: #7500c4;
	cursor: pointer;
}
.button a {
	color: white;
	letter-spacing: 3px;
	text-decoration: none;
}
@media only screen and (max-width: 1100px) {
	.main-content {
	  height: 60%;
	  width: 60%;
	}
	.name-title {
		display: block;
	}
  }
@media only screen and (max-width: 600px) {
	.main-content {
	  height: 75%;
	  width: 75%;
	}
  }
