.file-window {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem;
  text-align: center;
  line-height: 0.5rem;
  font-size: 0.8rem;
}
.file-item:hover {
  cursor: pointer;
}
.file-item img {
  width: 2.3rem;
  height: auto;
  transition: all ease-in-out 100ms;
}
.file-item img:hover {
  transform: translateY(-3px);
}
.file-item p {
  margin: 0;
}
