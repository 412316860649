.terminal-container {
  background-color: #171717;
  color: #00ff00;
  padding: 1rem;
  margin: 0 auto;
  min-height: 100%;
  font-size: small;
}
.terminal-entry {
  margin-bottom: 1px;
  white-space: pre-wrap; /* Ensures line breaks are preserved */
}
.terminal-prompt {
  color: #00ff00;
}
.terminal-input input {
  background: transparent;
  border: none;
  color: #00ff00;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
}

