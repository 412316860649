.projects-wrapper {
  margin: auto;
}
.portfolio-content {
  padding: 2rem;
}
/*portfolio tabs********************************************************/
.portfolio-tabs {
  display: flex;
  gap: 1rem;
  padding: 1rem 0  0 1rem;
  border-bottom: 2px solid black;
  background-color: white;
}
.portfolio-tab {
  border-radius: 5px 5px 0 0;
  border: 2px solid black;
  border-bottom: none;
  width: 5rem;
  text-align: center;
}
.portfolio-tab:hover {
  cursor: pointer;
}
.selected-tab {
  background-color: black;
  color: white;
}
.resume-link {
  text-decoration: none;
  border-radius: 2px;
  border: 2px solid black;
  padding: 0.3rem;
  margin: 0 0 2rem 0;
  color: black;
  background-color: white;
  float: right;
}
.resume-link:hover {
  cursor: pointer;
}
.portfolio-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}