.panel {
  position: absolute;
  border-radius: 3px;
  border: 2px solid black;
  top: 8.5rem;
  left: 1rem;
  box-shadow: 6px 6px 1px rgba(0, 0, 0, 0.5);
  background-color: white;
}
.panel .content {
  position: relative;
  height: calc(100% - 3.3rem);
  overflow-y: auto;
}
.panel-bottom-bar {
  height: 1.5rem;
  width: 100%;
  border: 2px solid black;
  border-left: transparent;
  background-color: white;
}
