body {
  height: 98vh;
  padding: 0;
}
body {
  background-color: white;
  background-size: 30px 30px;
  background-image:
    linear-gradient(to right, rgba(0, 0, 255, 0.3) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 255, 0.3) 1px, transparent 1px);
}
h1 {
  margin: 0;
}
.desktop-icons {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  position: absolute;
  margin: 3rem 0 0 0.75rem;
  padding: 0.4rem;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.8);
}
.icon {
  text-align: center;
  line-height: 0.5rem;
  font-size: 0.8rem;
}
.icon img {
  width: 2.3rem;
  height: auto;
  transition: all ease-in-out 100ms;
  cursor: pointer;
}
.icon img:hover {
  transform: translateY(-5px);
}
/*Top Bar*************************************************************/
.desktop-top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  padding: 0.3rem;
  background-color: white;
  border-bottom: 2px solid black;
  justify-content: right;
}
.desktop-top-bar img {
  width: 1.2rem;
  height: auto;
}
.link-wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
}
.link-wrapper a {
  padding: 0 0.5rem 0 0.5rem;
}
.link-wrapper img {
  transition: all ease-in-out 100ms;
}
.link-wrapper img:hover {
  transform: translateY(-3px);
}
.link-wrapper .time {
  font-weight: 500;
  font-size: 1.2rem;
  margin-left: 1rem;
}
