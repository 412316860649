.music-player {
  display: flex;
  height: 21.7rem;
}
.music-left-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
}
.music-player-title {
  font-size: 1.5rem;
  font-weight: 800;
}
.song-list-panel {
  border: 2px solid black;
  flex: 1;
  overflow-y: auto;
}
.song-list-panel ol li {
  text-decoration: underline;
  padding-bottom: 0.5rem;
}
.song-list-panel ol li div {
  cursor: pointer;
}
.song-selected {
  color: royalblue;
}
.music-player-controls {
  height: 2rem;
}
.music-play-panel {
  text-align: center;
  width: 52%;
  border: 2px solid black;
  margin: 1rem 1rem 1rem 0;
  padding: 1rem;
}
.music-album-cover {
  width: 100%;
  height: auto;
}
.music-album-cover img {
  width: 10rem;
  height: 10rem;
}
.music-controls {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.music-button {
  border-radius: 5px;
  background-color: rgb(19, 193, 68);
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  margin-top: 1rem;
}
